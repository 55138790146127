import {
    SIGNUP_SUCCESS,
    SIGNUP_FAIL,
    LOGIN_SUCCESS,
    LOGIN_FAIL,
    LOGOUT,
    FORGOT_PASSWORD_SUCCESS,
    FORGOT_PASSWORD_FAIL,
    RESET_PASSWORD_SUCCESS,
    RESET_PASSWORD_FAIL
} from '../actions/types';

const initialState = {
    token: localStorage.getItem('token'),
    isAuthenticated: null,
    loading: false,
    passwordResetRequested: false,
    passwordResetSuccessful: false
};

const authReducer = (state = initialState, action) => {
    const { type, payload } = action;

    switch (type) {
        case LOGIN_SUCCESS:
            localStorage.setItem('token', payload.access);
            return {
                ...state,
                isAuthenticated: true,
                loading: false,
                token: payload.access
            };
        case SIGNUP_SUCCESS:
            return {
                ...state,
                isAuthenticated: false,
                loading: true
            };
        case SIGNUP_FAIL:
        case LOGIN_FAIL:
        case LOGOUT:
            localStorage.removeItem('token');
            return {
                ...state,
                token: null,
                isAuthenticated: false,
                loading: false
            };
        case FORGOT_PASSWORD_SUCCESS:
            return {
                ...state,
                passwordResetRequested: true
            };

        case FORGOT_PASSWORD_FAIL:
            return {
                ...state,
                passwordResetRequested: false
            };
        case RESET_PASSWORD_SUCCESS:
            return {
                ...state,
                passwordResetSuccessful: true
            };
        case RESET_PASSWORD_FAIL:
            return {
                ...state,
                passwordResetSuccessful: false
            };
        default:
            return state;
    }
};

export default authReducer;
