import React from 'react';
import { Link, NavLink } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { logout } from '../actions/auth';
import logo from '../assets/images/logo2.png';
import Alert from './Alert';

const Navbar = () => {
    const dispatch = useDispatch();
    const { isAuthenticated, loading } = useSelector(state => state.auth);

    const authLinks = (
        <a className='navbar__top__auth__link' onClick={() => dispatch(logout())} href='#!'>Logout</a>
    );

    const guestLinks = (
        <>
            <Link className='navbar__top__auth__link' to='/login'>Login</Link>
            <Link className='navbar__top__auth__link' to='/signup'>Register</Link>
        </>
    );

    return (
        <>
            <nav className='navbar'>
                <div className='navbar__top'>
                    <div className='navbar__top__logo'>
                        <Link className='navbar__top__logo__link' to='/'>
                            <img src={logo} alt='Offgriddevelopments LLC' className='navbar__logo__image' />
                        </Link>
                    </div>
                    <div className='navbar__top__auth'>
                        {!loading && (<>{isAuthenticated ? authLinks : guestLinks}</>)}
                    </div>
                </div>
                <div className='navbar__bottom'>
                    <li className='navbar__bottom__item'>
                        <NavLink className='navbar__bottom__item__link' exact to='/'>Home</NavLink>
                    </li>
                    <li className='navbar__bottom__item'>
                        <NavLink className='navbar__bottom__item__link' exact to='/listings'>Listings</NavLink>
                    </li>
                    <li className='navbar__bottom__item'>
                        <NavLink className='navbar__bottom__item__link' exact to='/company'>Company</NavLink>
                    </li>
                    {/* <li className='navbar__bottom__item'>
                        <NavLink className='navbar__bottom__item__link' exact to='/contact'>Contact</NavLink>
                    </li> */}
                </div>
            </nav>
            <Alert />
        </>
    );
};

export default Navbar;
