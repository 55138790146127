import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import axiosInstance from '../axiosConfig';
import { Link, useParams } from 'react-router-dom';
import { loadStripe } from '@stripe/stripe-js';
import Cookies from 'js-cookie';

const stripePromise = loadStripe('pk_live_51PoFNCRsETdjcfB7u0auLNLvRcl45KGBnuOHsuhJhi1tsaaHwOHQ9APE6HVPO06jh1XXOYSRmf8H5RQHpuFdIb7Y00zx1U0r0X');

const ListingDetail = () => {
    const { id } = useParams();
    const [listing, setListing] = useState({});
    const [realtor, setRealtor] = useState({});
    const [price, setPrice] = useState(0);
    const [selectedMedia, setSelectedMedia] = useState(null);
    const [selectedMediaType, setSelectedMediaType] = useState('image');
    const [paymentType, setPaymentType] = useState('monthly');
    const [amount, setAmount] = useState(0);
    const [currentUser, setCurrentUser] = useState(null);

    const numberWithCommas = (x) => {
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    };

    const handleCheckout = async () => {
        try {
            const stripe = await stripePromise;
            const csrfToken = Cookies.get('csrftoken');
            
            if (!amount || amount <= 0) {
                alert('Please enter a valid amount.');
                return;
            }

            const response = await axiosInstance.post(
                '/payments/create-checkout-session/',
                { listing_id: listing.id, payment_type: paymentType, amount_paid: amount },
                {
                    headers: { 'X-CSRFToken': csrfToken, 'Content-Type': 'application/json' },
                    withCredentials: true,
                }
            );
            
            const session = response.data;
            if (session && session.id) {
                stripe.redirectToCheckout({ sessionId: session.id });
            } else {
                console.error("Error: Session ID not found");
            }
        } catch (error) {
            console.error("Error initiating checkout:", error);
            alert('An error occurred. Please try again.');
        }
    };

    useEffect(() => {
        const fetchListing = async () => {
            try {
                const res = await axiosInstance.get(`/api/listings/${id}`);
                setListing(res.data);
                setPrice(numberWithCommas(res.data.price));
                setSelectedMedia(res.data.photo_main);
            } catch (err) {
                console.error(err);
            }
        };

        fetchListing();
    }, [id]);

    useEffect(() => {
        const fetchRealtor = async () => {
            if (listing.realtor) {
                try {
                    const res = await axiosInstance.get(`/api/realtors/${listing.realtor}`);
                    setRealtor(res.data);
                } catch (err) {
                    console.error(err);
                }
            }
        };

        fetchRealtor();
    }, [listing.realtor]);

    useEffect(() => {
        const fetchCurrentUser = async () => {
            try {
                const res = await axiosInstance.get('/api/accounts/current-user/');
                setCurrentUser(res.data);
            } catch (err) {
                console.error('Error fetching current user:', err);
            }
        };

        fetchCurrentUser();
    }, []);

    const handleMediaClick = (media, type) => {
        setSelectedMedia(media);
        setSelectedMediaType(type);
    };

    const displayMedia = () => {
        const mediaItems = [];

        for (let i = 1; i <= 15; i++) {
            const photo = listing[`photo_${i}`];
            if (photo) {
                mediaItems.push(
                    <div key={i} className='listingdetail__thumbnails__thumbnail'>
                        <img
                            className='listingdetail__thumbnails__thumbnail__image'
                            src={photo}
                            alt=''
                            onClick={() => handleMediaClick(photo, 'image')}
                        />
                    </div>
                );
            }
        }

        for (let i = 1; i <= 5; i++) {
            const video = listing[`video_${i}`];
            if (video) {
                mediaItems.push(
                    <div key={`video_${i}`} className='listingdetail__thumbnails__thumbnail'>
                        <video
                            className='listingdetail__thumbnails__thumbnail__video'
                            onClick={() => handleMediaClick(video, 'video')}
                        >
                            <source src={video} type="video/mp4" />
                            Your browser does not support the video tag.
                        </video>
                    </div>
                );
            }
        }

        return (
            <div className='listingdetail__thumbnails'>
                {mediaItems}
            </div>
        );
    };

    return (
        <div className='listingdetail'>
            <Helmet>
                <title>Realest Estate - Listing | {`${listing.title}`}</title>
                <meta name='description' content='Listing detail' />
            </Helmet>
            <div className='row'>
                <div className='listingdetail__breadcrumb'>
                    <Link className='listingdetail__breadcrumb__link' to='/'>Home</Link> / {listing.title}
                </div>
            </div>
            
            <div className="listingdetail__content">
                <div className="listingdetail__media">
                    <div className='listingdetail__display'>
                        {selectedMediaType === 'image' ? (
                            <img className='listingdetail__display__image' src={selectedMedia} alt='' />
                        ) : (
                            <video className='listingdetail__display__video' controls>
                                <source src={selectedMedia} type="video/mp4" />
                                Your browser does not support the video tag.
                            </video>
                        )}
                    </div>
                    {displayMedia()}
                </div>

                <div className="listingdetail__payment">
                    {listing.sold_out ? (
                        <h2 style={{ marginTop: '2rem', fontSize: '18px', fontWeight: 'bold', marginLeft: '2rem' , color: "red"}}>
                            This property is sold out.
                        </h2>
                    ) : (
                        <>
                            <h2 style={{ marginTop: '2rem', fontSize: '18px', fontWeight: 'bold', marginLeft: '2rem' }}>
                                {listing.reserved_by && listing.reserved_by !== currentUser?.id ? (
                                    "Please take a look at our other available properties"
                                ) : "Start Payment"}
                            </h2>

                            {listing.reserved_by && listing.reserved_by !== currentUser?.id ? (
                                <>
                                    <p style={{ color: 'red', fontWeight: 'bold', fontSize: '18px', marginTop:'2rem', marginLeft:'2rem' }}>
                                        This listing is currently reserved by another user and is not available for purchase.
                                    </p>
                                    <Link 
                                        to="/listings" 
                                        style={{ 
                                            display: 'inline-block', 
                                            marginTop: '1rem', 
                                            marginLeft: '12rem',
                                            padding: '12px 20px', 
                                            backgroundColor: 'green', 
                                            color: 'white', 
                                            borderRadius: '23px',
                                            textDecoration: 'none',
                                            fontWeight: 'bold',
                                            fontSize: '16px'
                                        }}
                                    >
                                        View More Listings
                                    </Link>
                                </>
                            ) : (
                                <div>
                                    <div className='payment-form'>
                                        <label>
                                            Payment Type
                                            <select 
                                                value={paymentType} 
                                                onChange={(e) => setPaymentType(e.target.value)} 
                                                required
                                            >
                                                <option value="monthly">Monthly Installment Payment</option>
                                                <option value="late">Late Fees Payment</option>
                                                <option value="down">Down Payment</option>
                                                <option value="other">Other</option>
                                            </select>
                                        </label>
                                    </div>
                                    <div className='amount'>
                                        <label>
                                            Amount
                                            <input 
                                                type="number" 
                                                value={amount} 
                                                onChange={(e) => setAmount(e.target.value)} 
                                                required 
                                            />
                                        </label>
                                    </div>
                                    <button className= "payment-btn" onClick={handleCheckout}>Pay with Stripe</button>
                                </div>
                            )}
                        </>
                    )}
                </div>
            </div>
                    
            <div className='row'>
                <p className='listingdetail__description'>{listing.description}</p>
            </div>
            <div className='row'>
                <div className='col-1-of-2'>
                    <ul className='listingdetail__list'>
                        <li className='listingdetail__list__item'>✔ Land Type: {listing.land_type}</li>
                        <li className='listingdetail__list__item'>✔ Price: ${price}</li>
                        <li className='listingdetail__list__item'>✔ Acres: {listing.acres}</li>
                    </ul>
                </div>
                <div className='col-1-of-2'>
                    <ul className='listingdetail__list'>
                        <li className='listingdetail__list__item'>✔ Sale Type: {listing.sale_type}</li>
                        <li className='listingdetail__list__item'>✔ Address: {listing.address}</li>
                        <li className='listingdetail__list__item'>✔ City: {listing.city}</li>
                        <li className='listingdetail__list__item'>✔ State: {listing.state}</li>
                    </ul>
                </div>
            </div>
        </div>
    );
};

export default ListingDetail;
