import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import { useDispatch } from 'react-redux';
import { forgotPassword } from '../actions/auth';

const ForgotPassword = () => {
    const dispatch = useDispatch();
    const [email, setEmail] = useState('');
    const [submitted, setSubmitted] = useState(false);

    const onChange = e => setEmail(e.target.value);

    const onSubmit = e => {
        e.preventDefault();
        dispatch(forgotPassword(email));
        setSubmitted(true);
    };

    if (submitted) {
        return (
            <div className='auth'>
                <h1 className='auth__title'>Check Your Email</h1>
                <p className='auth__lead'>We've sent a password reset link to your email address.</p>
            </div>
        );
    }

    return (
        <div className='auth'>
            <Helmet>
                <title>Forgot Password</title>
                <meta name='description' content='forgot password page' />
            </Helmet>
            <h1 className='auth__title'>Forgot Password</h1>
            <p className='auth__lead'>Enter your email to reset your password</p>
            <form className='auth__form' onSubmit={onSubmit}>
                <div className='auth__form__group'>
                    <input 
                        className='auth__form__input'
                        type='email'
                        placeholder='Email'
                        name='email'
                        value={email}
                        onChange={onChange}
                        required
                    />
                </div>
                <button className='auth__form__button'>Send Reset Link</button>
            </form>
        </div>
    );
};

export default ForgotPassword;