import React from 'react';
import { Link } from 'react-router-dom';

const Success = () => {
  return (
    <div>
      <h1 style={{ fontSize:'24px', color: "green", textAlign: "center", marginTop: "12rem"}}>Payment Successful!</h1>
      <p style={{ fontSize: '20px', color: "black", fontWeight: "bold", textAlign: "center", marginTop: "4rem" }}>Thank you for choosing Off Grid Developments.</p>

      <Link 
        to='/' 
        style={{
          display: 'inline-block',
          marginTop: '1rem',
          padding: '16px 20px',
          backgroundColor: 'green',
          color: 'white',
          borderRadius: '5px',
          textDecoration: 'none',
          fontWeight: 'bold',
          marginLeft: "58rem",
          marginTop: "3rem",
          fontSize: "16px"
        }}
      >
        Go back to Home
      </Link> 
    </div>
  );
};

export default Success;
