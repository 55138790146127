import axiosInstance from '../axiosConfig';  // Import the centralized Axios instance
import { setAlert } from './alert';  // If you have an alert system in place
import {
    SIGNUP_SUCCESS,
    SIGNUP_FAIL,
    LOGIN_SUCCESS,
    LOGIN_FAIL,
    LOGOUT,
    FORGOT_PASSWORD_SUCCESS,
    FORGOT_PASSWORD_FAIL,
    RESET_PASSWORD_SUCCESS,
    RESET_PASSWORD_FAIL
} from './types';

// Login Action
export const login = (email, password) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json',
        },
    };

    const body = JSON.stringify({ email, password });

    try {
        // Make the POST request to the session-based login endpoint
        const res = await axiosInstance.post('/api/accounts/login/', body, config);

        dispatch({
            type: LOGIN_SUCCESS,
            payload: res.data
        });

        // Optionally, you can still use setAlert to notify the user
        dispatch(setAlert('Authenticated successfully', 'success'));
    } catch (err) {
        dispatch({
            type: LOGIN_FAIL
        });

        // Optionally, you can still use setAlert to notify the user
        dispatch(setAlert('Error Authenticating', 'error'));
    }
};

// Signup Action
export const signup = ({ name, email, password, password2 }) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    };

    const body = JSON.stringify({ name, email, password, password2 });

    try {
        const res = await axiosInstance.post(`/api/accounts/signup/`, body, config);
        
        dispatch({
            type: SIGNUP_SUCCESS,
            payload: res.data
        });

        dispatch(setAlert('Signup Successful', 'success'));
    } catch (err) {
        const errors = err.response?.data?.errors;

        if (errors) {
            errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
        }

        dispatch({
            type: SIGNUP_FAIL
        });
        dispatch(setAlert('Error Signing Up', 'error'));
    }
};

// Logout Action
export const logout = () => async dispatch => {
    try {
        await axiosInstance.post('/api/accounts/logout/');  // If you want to make a logout request to the backend

        dispatch({
            type: LOGOUT
        });

        dispatch(setAlert('Logout successful', 'success'));
    } catch (err) {
        dispatch(setAlert('Error logging out', 'error'));
    }
};
export const forgotPassword = (email) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    };

    const body = JSON.stringify({ email });

    try {
        await axiosInstance.post('/api/accounts/forgot-password/', body, config);

        dispatch({
            type: FORGOT_PASSWORD_SUCCESS
        });

        dispatch(setAlert('Password reset email sent', 'success'));
    } catch (err) {
        dispatch({
            type: FORGOT_PASSWORD_FAIL
        });

        dispatch(setAlert('Error sending password reset email', 'error'));
    }
};

export const resetPassword = (uidb64, token, new_password) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    };

    const body = JSON.stringify({ new_password });

    try {
        await axiosInstance.post(`/api/accounts/reset-password/${uidb64}/${token}/`, body, config);

        dispatch({
            type: RESET_PASSWORD_SUCCESS
        });

        dispatch(setAlert('Password reset successful', 'success'));
    } catch (err) {
        dispatch({
            type: RESET_PASSWORD_FAIL
        });

        dispatch(setAlert('Error resetting password', 'error'));
    }
};