import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import { useDispatch } from 'react-redux';
import { useParams, Navigate } from 'react-router-dom';
import { resetPassword } from '../actions/auth';

const ResetPassword = () => {
    const dispatch = useDispatch();
    const { uidb64, token } = useParams();
    const [formData, setFormData] = useState({
        new_password: '',
        re_new_password: ''
    });
    const [passwordReset, setPasswordReset] = useState(false);

    const { new_password, re_new_password } = formData;

    const onChange = e => setFormData({ ...formData, [e.target.name]: e.target.value });

    const onSubmit = e => {
        e.preventDefault();
        if (new_password === re_new_password) {
            dispatch(resetPassword(uidb64, token, new_password));
            setPasswordReset(true);
        } else {
            // Handle password mismatch error
            console.error('Passwords do not match');
        }
    };

    if (passwordReset) {
        return <Navigate to='/login' />;
    }

    return (
        <div className='auth'>
            <Helmet>
                <title>Reset Password</title>
                <meta name='description' content='reset password page' />
            </Helmet>
            <h1 className='auth__title'>Reset Password</h1>
            <p className='auth__lead'>Enter your new password</p>
            <form className='auth__form' onSubmit={onSubmit}>
                <div className='auth__form__group'>
                    <input
                        className='auth__form__input'
                        type='password'
                        placeholder='New Password'
                        name='new_password'
                        value={new_password}
                        onChange={onChange}
                        minLength='6'
                        required
                    />
                </div>
                <div className='auth__form__group'>
                    <input
                        className='auth__form__input'
                        type='password'
                        placeholder='Confirm New Password'
                        name='re_new_password'
                        value={re_new_password}
                        onChange={onChange}
                        minLength='6'
                        required
                    />
                </div>
                <button className='auth__form__button'>Reset Password</button>
            </form>
        </div>
    );
};

export default ResetPassword;