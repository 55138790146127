// Cancel.js
import React from 'react';

const Cancel = () => {
  return (
    <div>
      <h1 style={{ fontSize:'24px', color: "green", textAlign: "center", marginTop: "12rem"}}>Payment Canceled</h1>
      <p style={{ fontSize: '20px', color: "black", fontWeight: "bold", textAlign: "center", marginTop: "4rem" }}>Your payment didn't go through. Please check the card info and billing adress or select a different payment option</p>
    </div>
  );
};

export default Cancel;